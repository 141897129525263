import React from "react";
import { FaBlog, FaClockRotateLeft, FaRegCommentDots } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogComponent = () => {
  <Helmet>
    <meta
      name="description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      name="keywords"
      content="Full Stack Developer, MERN Stack, Laravel Developer, Next.js, Web Development, API Integration, React, Node.js, Express.js, MongoDB, PHP, JavaScript, Responsive Design, Software Engineer"
    />
    <meta name="author" content="UnusDev" />
    <meta name="robots" content="index, follow" />
    <meta name="language" content="English" />
    <meta name="distribution" content="global" />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://mernsolution.com/" />
    <meta
      property="og:title"
      content="UnusDev || Full Stack Developer - MERN, Laravel, Next.js"
    />
    <meta
      property="og:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="og:image"
      content="https://www.yourwebsite.com/images/og-image.jpg"
    />

    <meta property="facebook:card" content="summary_large_image" />
    <meta
      property="facebook:url"
      content="https://www.facebook.com/mernsolution/"
    />
    <meta
      property="facebook:title"
      content="Hello. My Name is Md Rubel. I am a MERN STOCK Developer. MongoDB || Express.js || React.js || Node.js || Next js)  #mernsolution"
    />
    <meta
      property="facebook:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="facebook:image"
      content="https://www.facebook.com/photo/?fbid=2517334298443010&set=a.1170899903086463"
    />
    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://x.com/mernsolution" />
    <meta
      property="twitter:title"
      content="Hello. My Name is Md Rubel. I am a MERN STOCK Developer. MongoDB || Express.js || React.js || Node.js || Next js)  #mernsolution"
    />
    <meta
      property="twitter:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="twitter:image"
      content="https://x.com/mernsolution/header_photo"
    />
    {/* LinkedIn */}
    <meta
      property="linkedin:profile"
      content="https://www.linkedin.com/in/mernsolution/"
    />
    <meta
      property="linkedin:title"
      content="JavaScript Developer || Node.js || React.js || NextJs || MongoDB || Express.js || OpenAI || NLP & Prompt Developer #mernsolution"
    />
    <meta
      property="linkedin:description"
      content="Hello,
A professional  MERN stack developer and author on CodeCanyon. I specialize in crafting enchanting web applications using React.js, with expertise in Node.js and MongoDB for robust backends. My journey is marked by meticulously crafted products, showcasing a commitment to quality and innovation.

In my recent projects, I've ventured into the exciting realm of SaaS development, particularly in creating a cutting-edge writing tool powered by AI. Leveraging technologies like the OPENAI API, I excel in integrating complex systems and algorithms, ensuring seamless and intelligent functionalities.

I thrive in problem-solving, from API integrations to building scalable architectures tailored for dynamic demands. As an avid learner of emerging technologies, I continuously evolve to ensure my work remains current and future-proof. Let's collaborate to bring your vision to life with innovative digital experiences.

If you are looking for a dedicated and skilled MENR Stack professional who can bring both technical expertise and strong soft skills to your team, please don't hesitate to contact me.

Warm regards,
Md.Yunus 
Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="linkedin:image"
      content="https://www.linkedin.com/in/mernsolution/overlay/background-image/"
    />
    {/* Favicon */}
    <link rel="icon" href="https://www.youtube.com/@mernsolution" />

    <meta property="github:url" content=" https://github.com/mernsolution" />
  </Helmet>

  return (
    <section className="py-[30px] md:py-[80px]">
      <div className="container">
        <div className="menuBox" data-aos="fade-up" data-aos-delay="50">
          <div className=" inline-block rounded-full border border-text px-[20px] py-[5px]">
            <div className="flex items-center gap-[6px]">
              <span>
                <FaBlog className="fa-light fa-user text-[14px] text-white" />
              </span>
              <span className="pl-[6px] text-[14px] text-white">Blog</span>
            </div>
          </div>
        </div>
        <br />
        <div className="mt-[10px] md:mt-[20px]">
          <h2
            className="text-[32px] font-semibold uppercase  leading-tight text-white md:text-[52px]"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Never Compromise For Our <br className="hidden md:block" />
            Portfolio to
            <span className="text-theme"> Quality!</span>
          </h2>
          <p
            className="mt-[20px] text-text lg:w-[60%]"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            The imperative for integrated, expansive, and seamless digital
            experiences is fueling software product design and development
            across organizations at an unprecedented scale. These demands
            require capabilities to imagine, build, and run digital products and
            services for new and existing.
          </p>
        </div>

        <div className="mt-[60px] md:mt-[80px]">
          <div className="grid  gap-y-[30px] md:grid-cols-12  md:gap-x-[30px]">
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="group rounded-xl bg-card p-[25px]">
                <div className="h-[260px] w-full overflow-hidden rounded-2xl">
                  <img
                    src="assets/images/blog/blog-1.png"
                    alt="Lariv - React Portfolio Template"
                    className="h-full w-full   object-cover transition-all duration-500 group-hover:scale-[110%]"
                  />
                </div>
                <div>
                  <p className="mt-[20px] inline-block rounded-md bg-theme/10 p-[4px] text-sm text-theme">
                    App Development
                  </p>
                  <Link to={"/blog-details"}>
                    <h2 className="mt-[12px] text-[20px] font-semibold transition-all duration-300 hover:text-theme md:text-[24px]">
                      All that You Believe Should Realize About Making
                    </h2>
                  </Link>

                  <p className="mt-[14px] text-base text-text">
                    SASS is poised to revolutionize how businesses operate by,
                    and scalable software platform.
                  </p>
                  <div className="mt-[20px] flex items-center gap-[20px]">
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaClockRotateLeft className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">
                        September 16, 2023
                      </span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaRegCommentDots className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">3 Comments</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 "
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="group rounded-xl bg-card p-[25px]">
                <div className="h-[260px] w-full overflow-hidden rounded-2xl">
                  <img
                    src="assets/images/blog/blog-2.png"
                    alt="Lariv - React Portfolio Template"
                    className="h-full w-full  object-cover transition-all duration-500 group-hover:scale-[110%]"
                  />
                </div>
                <div>
                  <p className="mt-[20px] inline-block rounded-md bg-theme/10 p-[4px] text-sm text-theme">
                    App Development
                  </p>
                  <Link to={"/blog-details"}>
                    <h2 className="mt-[12px] text-[20px] font-semibold transition-all duration-300 hover:text-theme md:text-[24px]">
                      All that You Believe Should Realize About Making
                    </h2>
                  </Link>

                  <p className="mt-[14px] text-base text-text">
                    SASS is poised to revolutionize how businesses operate by,
                    and scalable software platform.
                  </p>
                  <div className="mt-[20px] flex items-center gap-[20px]">
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaClockRotateLeft className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">
                        September 16, 2023
                      </span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaRegCommentDots className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">3 Comments</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="group rounded-xl bg-card p-[25px]">
                <div className="h-[260px] w-full overflow-hidden rounded-2xl">
                  <img
                    src="assets/images/blog/blog-3.png"
                    alt="Lariv - React Portfolio Template"
                    className="h-full w-full  object-cover transition-all duration-500 group-hover:scale-[110%]"
                  />
                </div>
                <div>
                  <p className="mt-[20px] inline-block rounded-md bg-theme/10 p-[4px] text-sm text-theme">
                    App Development
                  </p>
                  <Link to={"/blog-details"}>
                    <h2 className="mt-[12px] text-[20px] font-semibold transition-all duration-300 hover:text-theme md:text-[24px]">
                      All that You Believe Should Realize About Making
                    </h2>
                  </Link>

                  <p className="mt-[14px] text-base text-text">
                    SASS is poised to revolutionize how businesses operate by,
                    and scalable software platform.
                  </p>
                  <div className="mt-[20px] flex items-center gap-[20px]">
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaClockRotateLeft className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">
                        September 16, 2023
                      </span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaRegCommentDots className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">3 Comments</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="group rounded-xl bg-card p-[25px]">
                <div className="h-[260px] w-full overflow-hidden rounded-2xl">
                  <img
                    src="assets/images/blog/blog-4.png"
                    alt="Lariv - React Portfolio Template"
                    className="h-full w-full  object-cover transition-all duration-500 group-hover:scale-[110%]"
                  />
                </div>
                <div>
                  <p className="mt-[20px] inline-block rounded-md bg-theme/10 p-[4px] text-sm text-theme">
                    App Development
                  </p>
                  <Link to={"/blog-details"}>
                    <h2 className="mt-[12px] text-[20px] font-semibold transition-all duration-300 hover:text-theme md:text-[24px]">
                      All that You Believe Should Realize About Making
                    </h2>
                  </Link>

                  <p className="mt-[14px] text-base text-text">
                    SASS is poised to revolutionize how businesses operate by,
                    and scalable software platform.
                  </p>
                  <div className="mt-[20px] flex items-center gap-[20px]">
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaClockRotateLeft className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">
                        September 16, 2023
                      </span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaRegCommentDots className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">3 Comments</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 "
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="group rounded-xl bg-card p-[25px]">
                <div className="h-[260px] w-full overflow-hidden rounded-2xl">
                  <img
                    src="assets/images/blog/blog-5.png"
                    alt="Lariv - React Portfolio Template"
                    className="h-full w-full  object-cover transition-all duration-500 group-hover:scale-[110%]"
                  />
                </div>
                <div>
                  <p className="mt-[20px] inline-block rounded-md bg-theme/10 p-[4px] text-sm text-theme">
                    App Development
                  </p>
                  <Link to={"/blog-details"}>
                    <h2 className="mt-[12px] text-[20px] font-semibold transition-all duration-300 hover:text-theme md:text-[24px]">
                      All that You Believe Should Realize About Making
                    </h2>
                  </Link>

                  <p className="mt-[14px] text-base text-text">
                    SASS is poised to revolutionize how businesses operate by,
                    and scalable software platform.
                  </p>
                  <div className="mt-[20px] flex items-center gap-[20px]">
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaClockRotateLeft className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">
                        September 16, 2023
                      </span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaRegCommentDots className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">3 Comments</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="group rounded-xl bg-card p-[25px]">
                <div className="h-[260px] w-full overflow-hidden rounded-2xl">
                  <img
                    src="assets/images/blog/blog-6.png"
                    alt="Lariv - React Portfolio Template"
                    className="h-full w-full object-cover transition-all duration-500 group-hover:scale-[110%]"
                  />
                </div>
                <div>
                  <p className="mt-[20px] inline-block rounded-md bg-theme/10 p-[4px] text-sm text-theme">
                    App Development
                  </p>
                  <Link to={"/blog-details"}>
                    <h2 className="mt-[12px] text-[20px] font-semibold transition-all duration-300 hover:text-theme md:text-[24px]">
                      All that You Believe Should Realize About Making
                    </h2>
                  </Link>

                  <p className="mt-[14px] text-base text-text">
                    SASS is poised to revolutionize how businesses operate by,
                    and scalable software platform.
                  </p>
                  <div className="mt-[20px] flex items-center gap-[20px]">
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaClockRotateLeft className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">
                        September 16, 2023
                      </span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <span>
                        <FaRegCommentDots className="text-base text-theme" />
                      </span>
                      <span className="text-sm text-text">3 Comments</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* pagination */}
          <div className="mt-[60px]" data-aos="fade-up" data-aos-delay="100">
            <ul className=" flex justify-center space-x-4">
              <li className="group">
                <Link to={"/blog-details"} className="pagination">
                  <svg
                    className="fill-white group-hover:fill-white"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.06 12L11 11.06L7.94667 8L11 4.94L10.06 4L6.06 8L10.06 12Z" />
                  </svg>
                </Link>
              </li>
              <li className="group">
                <Link to={"/blog-details"} className="pagination">
                  1
                </Link>
              </li>
              <li className="group">
                <Link href="#" className="pagination">
                  2
                </Link>
              </li>
              <li className="group">
                <Link to="#" className="pagination">
                  ...
                </Link>
              </li>
              <li className="group">
                <Link to="#" className="pagination">
                  10
                </Link>
              </li>
              <li className="group">
                <Link to="#" className="pagination">
                  <svg
                    className="fill-white group-hover:fill-white"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.94 4L6 4.94L9.05333 8L6 11.06L6.94 12L10.94 8L6.94 4Z" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogComponent;
