import React from "react";
import { FaSeedling } from "react-icons/fa6";
import { Link } from "react-router-dom";
import image1 from "../assist/mernsolution-rubel.png";
import react from "../assist/react.png";
import node from "../assist/node1.png";
import { Helmet } from "react-helmet";

const HomeComponent = () => {
  <Helmet>
    <meta
      name="description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      name="keywords"
      content="Full Stack Developer, MERN Stack, Laravel Developer, Next.js, Web Development, API Integration, React, Node.js, Express.js, MongoDB, PHP, JavaScript, Responsive Design, Software Engineer"
    />
    <meta name="author" content="UnusDev" />
    <meta name="robots" content="index, follow" />
    <meta name="language" content="English" />
    <meta name="distribution" content="global" />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://mernsolution.com/" />
    <meta
      property="og:title"
      content="UnusDev || Full Stack Developer - MERN, Laravel, Next.js"
    />
    <meta
      property="og:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="og:image"
      content="https://www.yourwebsite.com/images/og-image.jpg"
    />

    <meta property="facebook:card" content="summary_large_image" />
    <meta
      property="facebook:url"
      content="https://www.facebook.com/mernsolution/"
    />
    <meta
      property="facebook:title"
      content="Hello. My Name is Md Rubel. I am a MERN STOCK Developer. MongoDB || Express.js || React.js || Node.js || Next js)  #mernsolution"
    />
    <meta
      property="facebook:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="facebook:image"
      content="https://www.facebook.com/photo/?fbid=2517334298443010&set=a.1170899903086463"
    />
    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://x.com/mernsolution" />
    <meta
      property="twitter:title"
      content="Hello. My Name is Md Rubel. I am a MERN STOCK Developer. MongoDB || Express.js || React.js || Node.js || Next js)  #mernsolution"
    />
    <meta
      property="twitter:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="twitter:image"
      content="https://x.com/mernsolution/header_photo"
    />
    {/* LinkedIn */}
    <meta
      property="linkedin:profile"
      content="https://www.linkedin.com/in/mernsolution/"
    />
    <meta
      property="linkedin:title"
      content="JavaScript Developer || Node.js || React.js || NextJs || MongoDB || Express.js || OpenAI || NLP & Prompt Developer #mernsolution"
    />
    <meta
      property="linkedin:description"
      content="Hello,
A professional  MERN stack developer and author on CodeCanyon. I specialize in crafting enchanting web applications using React.js, with expertise in Node.js and MongoDB for robust backends. My journey is marked by meticulously crafted products, showcasing a commitment to quality and innovation.

In my recent projects, I've ventured into the exciting realm of SaaS development, particularly in creating a cutting-edge writing tool powered by AI. Leveraging technologies like the OPENAI API, I excel in integrating complex systems and algorithms, ensuring seamless and intelligent functionalities.

I thrive in problem-solving, from API integrations to building scalable architectures tailored for dynamic demands. As an avid learner of emerging technologies, I continuously evolve to ensure my work remains current and future-proof. Let's collaborate to bring your vision to life with innovative digital experiences.

If you are looking for a dedicated and skilled MENR Stack professional who can bring both technical expertise and strong soft skills to your team, please don't hesitate to contact me.

Warm regards,
Md.Yunus 
Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="linkedin:image"
      content="https://www.linkedin.com/in/mernsolution/overlay/background-image/"
    />
    {/* Favicon */}
    <link rel="icon" href="https://www.youtube.com/@mernsolution" />

    <meta property="github:url" content=" https://github.com/mernsolution" />
  </Helmet>

  return (
    <section className="py-[30px] md:py-[80px]">
      <div className="container">
        {/* Intro Section */}
        <div className="grid grid-cols-12 gap-[30px]">
          <div className="order-2 col-span-12 mt-[40px] py-[40px] md:mt-0 md:pr-[60px] lg:order-1 lg:col-span-8">
            <p className="text-[22px]" data-aos="fade-up" data-aos-delay="50">
              <span className="text-theme">Hi!</span> There
            </p>
            <h2
              className="relative mt-[20px] inline-block text-[32px]  font-semibold leading-tight md:text-[62px]"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              HI, I'M Rubel!
              <span className=" absolute bottom-[15px] right-[-100px]">
                <img
                  src="assets/images/icon/icon-1.png"
                  alt="Lariv - React Portfolio Template"
                  className="wobble-hor-bottom"
                />
              </span>
            </h2>
            <h2
              className=" text-[52px] font-semibold leading-tight md:text-[62px]"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              CREATIVE <span className="text-theme">DEVELOPER!</span>
            </h2>
            <br />
            <p className="text-text" data-aos="fade-up" data-aos-delay="200">
              I am a skilled Web Developer with extensive experience in
              TypeScript and JavaScript, specializing in frameworks such as
              React,Next js, Node.js, and MongoDB. My expertise lies in rapidly
              learning new technologies and working closely with clients to
              develop efficient, scalable, and user-friendly solutions that
              address real-world challenges. As a contributor to CodeCanyon, I
              showcase and sell innovative AI tools that empower both developers
              and businesses. My commitment to delivering cutting-edge solutions
              drives me to push the boundaries of technology, aiming to make a
              significant positive impact in the digital space. Let's
              collaborate to transform your ideas into reality!
            </p>
            <div className="mt-[50px]" data-aos="fade-up" data-aos-delay="250">
              <Link to="/" className="btn">
                Hire Me Now!
              </Link>
            </div>

            {/* Count Section */}
            <div className="mt-[60px] grid grid-cols-12 gap-[30px] md:gap-2">
              <div
                className="col-span-12 md:col-span-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h2 className="  text-[50px] font-semibold  ">
                  <span className="counter">2</span>+
                </h2>
                <p className="   text-white">
                  YEARS OF AWESOME <br />
                  EXPERIENCE
                </p>
              </div>
              <div
                className="col-span-12 md:col-span-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h2 className="  text-[50px] font-semibold  ">
                  <span className="counter">30</span>+
                </h2>
                <p className="   text-white">
                  REAL PROJECTS COMPLETED <br />
                  ON THIS YEAR
                </p>
              </div>
              <div
                className="col-span-12 md:col-span-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h2 className="  text-[50px] font-semibold  ">
                  <span className="counter">200</span>+
                </h2>
                <p className="   text-white">
                  SEAL IN ENVATO <br />
                  MARKET
                </p>
              </div>
            </div>

            {/* Inner Card */}
            <div className="mt-[60px] grid grid-cols-12 gap-[30px]">
              <div
                className="col-span-12 md:col-span-4 "
                data-aos="fade-up"
                data-aos-delay="350"
              >
                <div className=" grid gap-[30px] rounded-lg border-2 border-dotted border-text p-[10px]">
                  <h3 className="text-center text-[24px]">Services</h3>
                  <div className="flex  justify-around  p-[5px]">
                    <img
                      src="assets/images/icon/ts.png"
                      alt="Lariv - React Portfolio Template"
                    />
                    <img
                      src="assets/images/icon/ts1.png"
                      alt="Lariv - React Portfolio Template"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-span-12 md:col-span-4 "
                data-aos="fade-up"
                data-aos-delay="350"
              >
                <div className=" grid gap-[10px] rounded-lg border-2 border-dotted border-text p-[20px]">
                  <h3 className="text-center text-[24px]">Projects</h3>
                  <div className="flex  justify-around">
                    <img
                      src="assets/images/show/show-1.png"
                      alt="Lariv - React Portfolio Template"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-span-12 md:col-span-4 "
                data-aos="fade-up"
                data-aos-delay="350"
              >
                <div className=" grid gap-[38px] rounded-lg border-2 border-dotted border-text p-[20px]">
                  <h3 className="text-center text-[24px]">About Us</h3>
                  <div className="flex  justify-around p-[10px]">
                    <img
                      src="assets/images/logo.svg"
                      alt="Lariv - React Portfolio Template"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" order-1 col-span-12 lg:order-2 lg:col-span-4 ">
            <div className=" relative mt-[50px] ">
              <div data-aos="fade-up" data-aos-delay="200">
                <img
                  src={image1}
                  alt="Lariv - React Portfolio Template"
                  className=" w-full "
                />
                <img
                  src={react}
                  alt="Lariv - React Portfolio Template"
                  className="slide-top-1 absolute left-[-10px] top-[90px] w-[60px] md:top-[170px] md:w-[80px] lg:top-[120px]"
                />
                <img
                  src={node}
                  alt="Lariv - React Portfolio Template"
                  className="slide-top-2 absolute right-[-10px] top-[260px] w-[60px] md:top-[450px] md:w-[80px] lg:top-[250px]"
                />
              </div>
              <div
                className="pulse absolute bottom-[-50px] right-[40px] flex items-center gap-[20px] rounded-full bg-card px-[40px] py-[18px] md:bottom-[-17px] lg:right-[-34px]"
                data-aos="fade-up"
                data-aos-delay="350"
              >
                <div>
                  <FaSeedling className="text-[40px] text-theme" />
                </div>
                <div>
                  <h2 className="text-[26px] font-semibold">1100k+</h2>
                  <p className="text-base text-white">Real Customers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeComponent;
