import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
import InitLoader from "../layout/InitLoader";
import MasterLayout from "../layout/MasterLayout";
import BlogDetailsComponent from "../components/BlogDetailsComponent";

const BlogDetails = () => {
  return (
    <Fragment>
      <MasterLayout>
        <Suspense fallback={<InitLoader />}>
          <Helmet>
            <meta
              name="description"
              content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
            />
            <meta
              name="keywords"
              content="Full Stack Developer, MERN Stack, Laravel Developer, Next.js, Web Development, API Integration, React, Node.js, Express.js, MongoDB, PHP, JavaScript, Responsive Design, Software Engineer"
            />
            <meta name="author" content="UnusDev" />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <meta name="distribution" content="global" />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://mernsolution.com/" />
            <meta
              property="og:title"
              content="UnusDev || Full Stack Developer - MERN, Laravel, Next.js"
            />
            <meta
              property="og:description"
              content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
            />
            <meta
              property="og:image"
              content="https://www.yourwebsite.com/images/og-image.jpg"
            />

            <meta property="facebook:card" content="summary_large_image" />
            <meta
              property="facebook:url"
              content="https://www.facebook.com/mernsolution/"
            />
            <meta
              property="facebook:title"
              content="Hello. My Name is Md Rubel. I am a MERN STOCK Developer. MongoDB || Express.js || React.js || Node.js || Next js)  #mernsolution"
            />
            <meta
              property="facebook:description"
              content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
            />
            <meta
              property="facebook:image"
              content="https://www.facebook.com/photo/?fbid=2517334298443010&set=a.1170899903086463"
            />
            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://x.com/mernsolution" />
            <meta
              property="twitter:title"
              content="Hello. My Name is Md Rubel. I am a MERN STOCK Developer. MongoDB || Express.js || React.js || Node.js || Next js)  #mernsolution"
            />
            <meta
              property="twitter:description"
              content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
            />
            <meta
              property="twitter:image"
              content="https://x.com/mernsolution/header_photo"
            />
            {/* LinkedIn */}
            <meta
              property="linkedin:profile"
              content="https://www.linkedin.com/in/mernsolution/"
            />
            <meta
              property="linkedin:title"
              content="JavaScript Developer || Node.js || React.js || NextJs || MongoDB || Express.js || OpenAI || NLP & Prompt Developer #mernsolution"
            />
            <meta
              property="linkedin:description"
              content="Hello,
A professional  MERN stack developer and author on CodeCanyon. I specialize in crafting enchanting web applications using React.js, with expertise in Node.js and MongoDB for robust backends. My journey is marked by meticulously crafted products, showcasing a commitment to quality and innovation.

In my recent projects, I've ventured into the exciting realm of SaaS development, particularly in creating a cutting-edge writing tool powered by AI. Leveraging technologies like the OPENAI API, I excel in integrating complex systems and algorithms, ensuring seamless and intelligent functionalities.

I thrive in problem-solving, from API integrations to building scalable architectures tailored for dynamic demands. As an avid learner of emerging technologies, I continuously evolve to ensure my work remains current and future-proof. Let's collaborate to bring your vision to life with innovative digital experiences.

If you are looking for a dedicated and skilled MENR Stack professional who can bring both technical expertise and strong soft skills to your team, please don't hesitate to contact me.

Warm regards,
Md.Yunus 
Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
            />
            <meta
              property="linkedin:image"
              content="https://www.linkedin.com/in/mernsolution/overlay/background-image/"
            />
            {/* Favicon */}
            <link rel="icon" href="https://www.youtube.com/@mernsolution" />

            <meta
              property="github:url"
              content=" https://github.com/mernsolution"
            />
          </Helmet>
          <BlogDetailsComponent />
        </Suspense>
      </MasterLayout>
    </Fragment>
  );
};

export default BlogDetails;
