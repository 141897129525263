import React from "react";
import {
  FaBlackberry,
  FaCodeMerge,
  FaDeezer,
  FaDigitalOcean,
  FaHtml5,
  FaMobileScreen,
  FaUikit,
} from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Helmet } from "react-helmet";
const ServiceComponent = () => {
  <Helmet>
    <meta
      name="description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      name="keywords"
      content="Full Stack Developer, MERN Stack, Laravel Developer, Next.js, Web Development, API Integration, React, Node.js, Express.js, MongoDB, PHP, JavaScript, Responsive Design, Software Engineer"
    />
    <meta name="author" content="UnusDev" />
    <meta name="robots" content="index, follow" />
    <meta name="language" content="English" />
    <meta name="distribution" content="global" />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://mernsolution.com/" />
    <meta
      property="og:title"
      content="UnusDev || Full Stack Developer - MERN, Laravel, Next.js"
    />
    <meta
      property="og:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="og:image"
      content="https://www.yourwebsite.com/images/og-image.jpg"
    />

    <meta property="facebook:card" content="summary_large_image" />
    <meta
      property="facebook:url"
      content="https://www.facebook.com/mernsolution/"
    />
    <meta
      property="facebook:title"
      content="Hello. My Name is Md Rubel. I am a MERN STOCK Developer. MongoDB || Express.js || React.js || Node.js || Next js)  #mernsolution"
    />
    <meta
      property="facebook:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="facebook:image"
      content="https://www.facebook.com/photo/?fbid=2517334298443010&set=a.1170899903086463"
    />
    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://x.com/mernsolution" />
    <meta
      property="twitter:title"
      content="Hello. My Name is Md Rubel. I am a MERN STOCK Developer. MongoDB || Express.js || React.js || Node.js || Next js)  #mernsolution"
    />
    <meta
      property="twitter:description"
      content="Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="twitter:image"
      content="https://x.com/mernsolution/header_photo"
    />
    {/* LinkedIn */}
    <meta
      property="linkedin:profile"
      content="https://www.linkedin.com/in/mernsolution/"
    />
    <meta
      property="linkedin:title"
      content="JavaScript Developer || Node.js || React.js || NextJs || MongoDB || Express.js || OpenAI || NLP & Prompt Developer #mernsolution"
    />
    <meta
      property="linkedin:description"
      content="Hello,
A professional  MERN stack developer and author on CodeCanyon. I specialize in crafting enchanting web applications using React.js, with expertise in Node.js and MongoDB for robust backends. My journey is marked by meticulously crafted products, showcasing a commitment to quality and innovation.

In my recent projects, I've ventured into the exciting realm of SaaS development, particularly in creating a cutting-edge writing tool powered by AI. Leveraging technologies like the OPENAI API, I excel in integrating complex systems and algorithms, ensuring seamless and intelligent functionalities.

I thrive in problem-solving, from API integrations to building scalable architectures tailored for dynamic demands. As an avid learner of emerging technologies, I continuously evolve to ensure my work remains current and future-proof. Let's collaborate to bring your vision to life with innovative digital experiences.

If you are looking for a dedicated and skilled MENR Stack professional who can bring both technical expertise and strong soft skills to your team, please don't hesitate to contact me.

Warm regards,
Md.Yunus 
Experienced Full Stack Developer specializing in MERN Stack, Laravel, and Next.js. Delivering high-quality web development, API integration, and responsive design solutions to elevate your business."
    />
    <meta
      property="linkedin:image"
      content="https://www.linkedin.com/in/mernsolution/overlay/background-image/"
    />
    {/* Favicon */}
    <link rel="icon" href="https://www.youtube.com/@mernsolution" />

    <meta property="github:url" content=" https://github.com/mernsolution" />
  </Helmet>

  return (
    <section className="py-[30px] md:py-[80px]">
      <div className="container">
        <div className="menuBox" data-aos="fade-up" data-aos-delay="50">
          <div className=" inline-block rounded-full border border-text px-[20px] py-[5px]">
            <div className="flex items-center gap-[6px]">
              <span>
                <FaCodeMerge className="fa-light fa-user text-[14px] text-white" />
              </span>
              <span className="pl-[6px] text-[14px] text-white">
                All Service
              </span>
            </div>
          </div>
        </div>
        <br />
        <div className="mt-[10px] md:mt-[20px]">
          <h2
            className="text-[32px] font-semibold uppercase  leading-tight text-white md:text-[52px]"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            All the general services that
            <br className="hidden md:block" />I provide to
            <span className="text-theme"> clients!</span>
          </h2>
          <p
            className="mt-[20px] text-text lg:w-[60%]"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            The imperative for integrated, expansive, and seamless digital
            experiences is fueling software product design and development
            across organizations at an unprecedented scale. These demands
            require capabilities to imagine, build, and run digital products and
            services for new and existing.
          </p>
        </div>

        <div className="mt-[60px] md:mt-[80px]">
          <div className="grid  gap-y-[60px] md:grid-cols-12  md:gap-x-[30px]">
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="group rounded-2xl bg-btn p-[30px]">
                <div>
                  <FaBlackberry className="text-[60px] text-theme" />
                </div>
                <h2 className="pt-5 text-[20px] font-medium text-white md:text-[26px]">
                  AI Tools
                </h2>
                <p className="mt-[10px] text-text">
                  I am developing AI-based tools like text-to-image,
                  text-to-video , text to audio, text to musigc, AI chatbot,
                  Text to article generate tools, many more
                </p>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="group rounded-2xl bg-btn p-[30px]">
                <div>
                  <FaHtml5 className="text-[60px] text-theme" />
                </div>
                <h2 className="pt-5 text-[20px] font-medium text-white md:text-[26px]">
                  Website Development
                </h2>
                <p className="mt-[10px] text-text">
                  full-stack website on MERN Stack, using Frontend frameworks
                  such as React and NextJS are available. Bootstrap 5, backend
                  node js, express js, mongoDB
                </p>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="group rounded-2xl bg-btn p-[30px]">
                <div>
                  <FaDeezer className="text-[60px] text-theme" />
                </div>
                <h2 className="pt-5 text-[20px] font-medium text-white md:text-[26px]">
                  Theme Design
                </h2>
                <p className="mt-[10px] text-text">
                  Sketch, Figma to React JS, or Next JS, we are providing high
                  quality clean, responsive and error free code, An Author in
                  Envato Market.
                </p>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="group rounded-2xl bg-btn p-[30px]">
                <div>
                  <FaMobileScreen className="text-[60px] text-theme" />
                </div>
                <h2 className="pt-5 text-[20px] font-medium text-white md:text-[26px]">
                  API Integration
                </h2>
                <p className="mt-[10px] text-text">
                  Counseled for new companies, and teamed up with capable
                  individuals to make computerized items for both business and
                  purchaser use.
                </p>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="group rounded-2xl bg-btn p-[30px]">
                <div>
                  <FaDigitalOcean className="text-[60px] text-theme" />
                </div>
                <h2 className="pt-5 text-[20px] font-medium text-white md:text-[26px]">
                  Digital Marketing
                </h2>
                <p className="mt-[10px] text-text">
                  Counseled for new companies, and teamed up with capable
                  individuals to make computerized items for both business and
                  purchaser use.
                </p>
              </div>
            </div>
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="group rounded-2xl bg-btn p-[30px]">
                <div>
                  <FaUikit className="text-[60px] text-theme" />
                </div>
                <h2 className="pt-5 text-[20px] font-medium text-white md:text-[26px]">
                  UI/UX Design
                </h2>
                <p className="mt-[10px] text-text">
                  Counseled for new companies, and teamed up with capable
                  individuals to make computerized items for both business and
                  purchaser use.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Review Information */}
        <div className="mt-[100px]">
          <h2
            className="text-[32px] font-semibold"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            What People Says?:
          </h2>

          {/* Slider */}
          <div className="mt-[30px]" data-aos="fade-up" data-aos-delay="150">
            <Swiper
              modules={[Pagination]}
              spaceBetween={30}
              slidesPerView={4}
              slidesPerGroup={2}
              speed={1000}
              pagination={{ clickable: true }}
              autoplay={{ delay: 6000 }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                500: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                768: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                992: {
                  slidesPerView: 2,
                  slidesPerGroup: 1,
                },
                1200: {
                  slidesPerView: 3,
                  slidesPerGroup: 2,
                },
                1400: {
                  slidesPerView: 3,
                  slidesPerGroup: 2,
                },
              }}
            >
              <SwiperSlide>
                <div className=" divide-y divide-dashed divide-theme rounded-xl  bg-card px-[30px] py-[40px] shadow-none">
                  <p className="pb-[30px] text-[18px] italic text-text">
                    I recently had the pleasure of using LoraXD for my business,
                    and I must say it has exceeded my expectations in every way
                    possible.
                  </p>

                  <div className="flex items-center gap-4 pt-[30px]">
                    <div className="inline-block w-[80px] overflow-hidden rounded-full">
                      <img
                        src="assets/images/user/user-1.png"
                        alt="Lariv - React Portfolio Template"
                      />
                    </div>
                    <div className="grid gap-[10px]">
                      <p className="text-base font-semibold text-white  lg:text-[24px]">
                        Larry N. Alexandre
                      </p>
                      <p>565 Snider Street Denver, CO 80202</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" divide-y divide-dashed divide-theme rounded-xl  bg-card px-[30px] py-[40px] shadow-none">
                  <p className="pb-[30px] text-[18px] italic text-text">
                    I recently had the pleasure of using LoraXD for my business,
                    and I must say it has exceeded my expectations in every way
                    possible.
                  </p>

                  <div className="flex items-center gap-4 pt-[30px]">
                    <div className="inline-block w-[80px] overflow-hidden rounded-full">
                      <img
                        src="assets/images/user/user-2.png"
                        alt="Lariv - React Portfolio Template"
                      />
                    </div>
                    <div className="grid gap-[10px]">
                      <p className="text-base font-semibold text-white  lg:text-[24px]">
                        Larry N. Alexandre
                      </p>
                      <p>565 Snider Street Denver, CO 80202</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" divide-y divide-dashed divide-theme rounded-xl  bg-card px-[30px] py-[40px] shadow-none">
                  <p className="pb-[30px] text-[18px] italic text-text">
                    I recently had the pleasure of using LoraXD for my business,
                    and I must say it has exceeded my expectations in every way
                    possible.
                  </p>

                  <div className="flex items-center gap-4 pt-[30px]">
                    <div className="inline-block w-[80px] overflow-hidden rounded-full">
                      <img
                        src="assets/images/user/user-3.png"
                        alt="Lariv - React Portfolio Template"
                      />
                    </div>
                    <div className="grid gap-[10px]">
                      <p className="text-base font-semibold text-white  lg:text-[24px]">
                        Larry N. Alexandre
                      </p>
                      <p>565 Snider Street Denver, CO 80202</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" divide-y divide-dashed divide-theme rounded-xl  bg-card px-[30px] py-[40px] shadow-none">
                  <p className="pb-[30px] text-[18px] italic text-text">
                    I recently had the pleasure of using LoraXD for my business,
                    and I must say it has exceeded my expectations in every way
                    possible.
                  </p>

                  <div className="flex items-center gap-4 pt-[30px]">
                    <div className="inline-block w-[80px] overflow-hidden rounded-full">
                      <img
                        src="assets/images/user/user-4.png"
                        alt="Lariv - React Portfolio Template"
                      />
                    </div>
                    <div className="grid gap-[10px]">
                      <p className="text-base font-semibold text-white  lg:text-[24px]">
                        Larry N. Alexandre
                      </p>
                      <p>565 Snider Street Denver, CO 80202</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=" divide-y divide-dashed divide-theme rounded-xl  bg-card px-[30px] py-[40px] shadow-none">
                  <p className="pb-[30px] text-[18px] italic text-text">
                    I recently had the pleasure of using LoraXD for my business,
                    and I must say it has exceeded my expectations in every way
                    possible.
                  </p>

                  <div className="flex items-center gap-4 pt-[30px]">
                    <div className="inline-block w-[80px] overflow-hidden rounded-full">
                      <img
                        src="assets/images/user/user-5.png"
                        alt="Lariv - React Portfolio Template"
                      />
                    </div>
                    <div className="grid gap-[10px]">
                      <p className="text-base font-semibold text-white  lg:text-[24px]">
                        Larry N. Alexandre
                      </p>
                      <p>565 Snider Street Denver, CO 80202</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceComponent;
